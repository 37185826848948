import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';

import Navbar from '@components/navbar';
import OverviewNav from '@components/overview_nav';
import SEO from '@components/Seo';
import { Sb } from '@types';

type HomeProps = {
  location: any;
};

type Bloks = {
  navigation: Sb.Story;
  sidebar: Sb.Story;
};

export type PageCtx = {
  theme: 'brand' | 'ui' | 'code' | 'overview' | '';
  setCtx?(props: any): void;
};

const PageCtx = createContext<PageCtx>({
  theme: '',
});

export const usePageCtx = () => useContext(PageCtx);

const Home: FC<HomeProps> = ({ children, location }) => {
  const [ctx, setCtx] = useState<PageCtx>({
    theme: '',
  });

  const { navigation, sidebar } = useStaticQuery<Bloks>(graphql`
    query Navigation {
      navigation: storyblokEntry(slug: { eq: "homepage-navi" }) {
        name
        full_slug
        content
      }

      sidebar: storyblokEntry(slug: { eq: "overview-nav" }) {
        name
        full_slug
        content
      }
    }
  `);

  let navbarBlok = {
    ...navigation,
    content: parseJson(navigation.content),
  };

  let sideNavBlok = {
    ...sidebar,
    content: parseJson(sidebar.content),
  };

  useEffect(() => {
    setCtx({
      theme: getPageTheme(location),
    });
  }, []);

  return (
    <PageCtx.Provider value={{ ...ctx, setCtx }}>
      <div id="layout__home" className={clsx(``)}>
        <SEO />
        {location.pathname === '/' && <Navbar blok={navbarBlok.content} />}
        <main
          id={getPageTheme(location)}
          className={clsx(renderHomepageClassNames(location), renderThemePageClassNames(location))}
        >
          {location.pathname !== '/' && (
            <OverviewNav
              blok={sideNavBlok.content}
              pageTheme={`${getPageTheme(location)}`}
              navBlok={navbarBlok.content}
            />
          )}
          {children}
        </main>
      </div>
    </PageCtx.Provider>
  );
};

function renderHomepageClassNames({ pathname }: any): any {
  return pathname === '/' && {};
}

function renderThemePageClassNames({ pathname }: any): any {
  const BODY_CONTAINER = 'flex relative max-w-full overflow-hidden';
  return (
    pathname !== '/' && {
      [BODY_CONTAINER]: true,
    }
  );
}

function getPageTheme({ pathname }: any): 'brand' | 'ui' | 'code' {
  return pathname.match(/(brand|ui|code|overview)/g)?.[0];
}

const parseJson = (payload: any): unknown => isString(payload) && JSON.parse(payload);

function isString(content: unknown): content is string {
  return typeof content === 'string';
}

export default Home;
