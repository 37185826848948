import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import DynamicComponent from '@components/DynamicComponent';
import { globalHistory } from '@reach/router';
import useWindowWidth from '@hooks/useWindowWidth';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faLockAlt, faBars, faTimes } from '@fortawesome/sharp-regular-svg-icons';
import OverviewNavItem from '@components/overview_nav_item';

import { getUserInitials, isAuthenticated } from '../utils/authentication';

function OverviewNav(props) {
  const [mobileNav, setMobileNav] = useState(false);
  const [openNav, setOpenNav] = useState();
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(false);

  const windowWidth = useWindowWidth();

  const path = globalHistory.location.pathname.split('/').slice(1, 2).join('/');

  useEffect(() => {
    if (path === props.pageTheme) {
      setOpenNav(path);
    }
  }, [props.pageTheme, path, openNav]);

  const handleDropdown = (e) => {
    setOpen(!open);
  };
  const handleClick = () => {
    setCheck(!check);
  };

  useEffect(() => {
    if (check) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [check]);

  useEffect(() => {
    windowWidth < 900 ? setMobileNav(true) : setMobileNav(false);
  }, [windowWidth]);

  const renderInitials = () => {
    const initials = getUserInitials();

    return (
      <li onClick={() => setOpen(!open)} className={clsx('list-none')}>
        <div
          className={clsx(
            'flex items-center justify-center',
            'w-14 h-14 p-1',
            'bg-primary rounded-full',
            'border border-neutral',
            'cursor-pointer',
            'relative',
          )}
        >
          <span className={clsx('text-sm')}>{'CM'}</span>

          {open && (
            <a
              href="/cdn-cgi/access/logout"
              className={clsx(
                'absolute -left-px -top-px w-14',
                'w-14 h-14',
                'bg-primary rounded-full',
                'group',
                'border border-neutral-black',
              )}
              onClick={handleDropdown}
            >
              <div
                className={clsx(
                  'bg-neutral-black w-full h-full flex items-center justify-center rounded-full',
                )}
              >
                <div className={clsx('flex items-center justify-start')}>
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className={clsx('text-neutral-white group-hover:text-accent')}
                  />
                </div>
              </div>
            </a>
          )}
        </div>
      </li>
    );
  };

  const renderLogin = () => {
    return (
      <a
        href="/auth/"
        className={clsx(
          'w-14 h-14',
          'flex justify-center items-center',
          'text-button-ghost-enabled hover:text-button-ghost-hover',
          'bg-button-ghost-enabled hover:bg-button-ghost-hover',
          'rounded-xl focus-visible:ring-2 focus-visible:ring-accent',
        )}
      >
        <FontAwesomeIcon icon={faLockAlt} />
      </a>
    );
  };

  function renderMobileNav() {
    return (
      <div
        className={clsx(
          'fixed w-full h-18 z-1000',
          'pl-4 py-1',
          'bg-neutral-lightShade border-b border-b-neutral-lowContrast',
        )}
      >
        <div className={clsx('flex justify-between items-center w-full h-18')}>
          <a
            href="/"
            className={clsx(
              'w-12 h-12',
              'flex items-center justify-center p-1',
              'rounded-xl focus-visible:ring-2 focus-visible:ring-accent',
            )}
          >
            <img
              src="https://truelayer-public-assets.s3-eu-west-1.amazonaws.com/logo/mark/tl-charcoal.svg"
              alt="logo"
            />
          </a>
          <div className={clsx('avatar-desktop', 'flex flex-row items-center justify-end w-full')}>
            <button
              className={clsx(
                'flex cursor-pointer',
                'w-12 h-12 items-center justify-center m-0',
                'rounded-xl focus-visible:ring-2 focus-visible:ring-accent',
              )}
              onClick={handleClick}
            >
              {check ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
            </button>
            {isAuthenticated() ? renderInitials() : renderLogin()}
          </div>
        </div>
        <div
          className={clsx(
            'absolute left-0 top-16 w-screen h-screen z-1000',
            'flex flex-col gap-y-4 p-4',
            'transition-transform duration-short overflow-scroll',
            'bg-neutral border-t border-t-neutral-lowContrast',
            check ? 'translate-x-0' : '-translate-x-full',
          )}
        >
          {props.blok.overview_items &&
            props.blok.overview_items.map((blok) => (
              <DynamicComponent blok={blok} key={blok._uid} />
            ))}
          {props.blok.brand_nav &&
            props.blok.brand_nav.map((blok) => (
              <DynamicComponent blok={blok} key={blok._uid} pageTheme={props.pageTheme} />
            ))}
          {props.blok.ui_nav &&
            props.blok.ui_nav.map((blok) => (
              <DynamicComponent blok={blok} key={blok._uid} pageTheme={props.pageTheme} />
            ))}
          {props.blok.code_nav &&
            props.blok.code_nav.map((blok) => (
              <DynamicComponent blok={blok} key={blok._uid} pageTheme={props.pageTheme} />
            ))}
        </div>
      </div>
    );
  }

  function renderDesktopNav() {
    return (
      <div className={clsx('flex min-w-70 z-995', 'relative')}>
        <div className={clsx('w-full h-full bg-neutral-lightShade')}>
          <div className={clsx('fixed w-70', 'top-0 bottom-0 overflow-y-auto')}>
            <div className={clsx('h-full', 'flex')}>
              <div
                className={clsx(
                  'h-full w-20 fixed',
                  'flex flex-col gap-y-4 items-center py-4 px-2',
                  'bg-neutral-shade',
                  'border-r border-r-neutral-lowContrast',
                )}
              >
                <a
                  href="/"
                  className={clsx('w-12 h-12', 'flex items-center justify-center', 'p-1')}
                >
                  <img
                    src="https://truelayer-public-assets.s3-eu-west-1.amazonaws.com/logo/mark/tl-charcoal.svg"
                    alt="logo"
                  />
                </a>
                <div className={clsx('h-full w-full', 'flex flex-col gap-y-4')}>
                  <OverviewNavItem
                    link={props.blok.overview_items[0].link.cached_url}
                    text={props.blok.overview_items[0].text}
                    pageTheme={props.pageTheme}
                    id={'overview'}
                  />
                  <OverviewNavItem
                    link={props.blok.brand_nav[0].link.cached_url}
                    text={props.blok.brand_nav[0].text}
                    pageTheme={props.pageTheme}
                    id={'brand'}
                  />
                  <OverviewNavItem
                    link={props.blok.ui_nav[0].link.cached_url}
                    text={props.blok.ui_nav[0].text}
                    pageTheme={props.pageTheme}
                    id={'ui'}
                  />
                  <OverviewNavItem
                    link={props.blok.code_nav[0].link.cached_url}
                    text={props.blok.code_nav[0].text}
                    pageTheme={props.pageTheme}
                    id={'code'}
                  />
                </div>
                <div className={clsx('avatar-desktop')}>
                  {isAuthenticated() ? renderInitials() : renderLogin()}
                </div>
              </div>
              <div
                className={clsx(
                  'flex flex-col gap-y-2 pl-20',
                  'text-sm w-full py-4 px-2',
                  'bg-neutral-lightShade',
                  'border-r border-r-neutral-lowContrast',
                )}
              >
                {renderNavContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    function renderNavContent() {
      if (openNav === 'overview') {
        return (
          <>
            {props.blok.overview_items &&
              props.blok.overview_items[0].doc_nav_first.map((blok) => (
                <DynamicComponent blok={blok} key={blok._uid} pageTheme={props.pageTheme} />
              ))}
          </>
        );
      }
      if (openNav === 'brand') {
        return (
          <>
            {props.blok.brand_nav &&
              props.blok.brand_nav[0].doc_nav_first.map((blok) => (
                <DynamicComponent blok={blok} key={blok._uid} pageTheme={props.pageTheme} />
              ))}
          </>
        );
      }
      if (openNav === 'ui') {
        return (
          <>
            {props.blok.ui_nav &&
              props.blok.ui_nav[0].doc_nav_first.map((blok) => (
                <DynamicComponent blok={blok} key={blok._uid} pageTheme={props.pageTheme} />
              ))}
          </>
        );
      }
      if (openNav === 'code') {
        return (
          <>
            {props.blok.code_nav &&
              props.blok.code_nav[0].doc_nav_first.map((blok) => (
                <DynamicComponent blok={blok} key={blok._uid} pageTheme={props.pageTheme} />
              ))}
          </>
        );
      } else {
        return <></>;
      }
    }
  }

  return <>{mobileNav ? renderMobileNav() : renderDesktopNav()}</>;
}

export default OverviewNav;
