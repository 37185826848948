import React from 'react';
import clsx from 'clsx';

const Tag = (props) => {
  let icon;

  if (props.icon === '') {
    icon = null;
  } else {
    icon = <i className={`fas fa-${props.icon}`} />;
  }

  return (
    <span
      className={clsx(
        'inline-flex items-center relative my-1 mr-2',
        'py-2 px-4',
        'bg-tag-enabled rounded-lg',
        props.bg_color === 'sun' && 'bg-tag-yellow',
        props.bg_color === 'kashmir' && 'bg-tag-green',
        props.bg_color === 'cloud burst' && 'bg-tag-magenta',
        props.bg_color === 'soft azure' && 'bg-tag-pink',
        props.bg_color === 'dark violet' && 'bg-tag-lavender',
        props.bg_color === 'violet' && 'bg-tag-azure',
        props.bg_color === 'burnt sun' && 'bg-tag-semantic-blue',
        props.bg_color === 'light grey' && 'bg-tag-charcoal',
      )}
    >
      {icon}
      {props.text}
    </span>
  );
};
export default Tag;
