import loadable, { LoadableComponent } from '@loadable/component';

export type ComponentNames =
  | 'page'
  | 'grid'
  | 'parent'
  | 'section_default'
  | 'section_full_width'
  | 'hero'
  | 'centre_text'
  | 'layout_split'
  | 'nav_item'
  | 'button'
  | 'value_features'
  | 'audience_features'
  | 'footer'
  | 'homepage_navi'
  | 'footer_nav_item'
  | 'button_row'
  // Docs
  | 'docs_page'
  | 'docs_code_page'
  | 'docs_navi'
  | 'docs_nav_card'
  | 'docs_header'
  | 'docs_subheader'
  | 'docs_grid'
  | 'sub_nav_item'
  | 'docs_page_nav'
  | 'docs_page_nav_item'
  | 'docs_page_nav_icon'
  | 'docs_page_grid'
  | 'docs_body'
  | 'docs_content'
  | 'docs_card'
  | 'docs_title'
  | 'docs_divider'
  | 'docs_image'
  | 'values_principles'
  | 'principles'
  | 'tooltip'
  | 'tag'
  | 'docs_ui_page'
  | 'docs_overview_page'
  | 'doc_nav'
  | 'doc_nav_zero'
  | 'doc_nav_first'
  | 'doc_nav_second'
  | 'doc_nav_third'
  | 'doc_nav_fourth'
  | 'code_blok'
  | 'checkbox'
  | 'docs_tabs'
  | 'docs_tab'
  | 'docs_tab_panel'
  // Table
  | 'table'
  | 'table_row'
  | 'cell_default'
  | 'cell_color'
  | 'cell_icon'
  | 'cell_image'
  | 'docs_fw_image'
  | 'important_message'
  | 'cell_multi_icon'
  | 'sub_section'
  // Card
  | 'content_card'
  | 'card_color'
  | 'card_color_large'
  | 'card_color_accessibility'
  | 'card_note'
  | 'card_component'
  | 'card_image'
  | 'card_link'
  | 'card_do_dont'
  | 'card_tag'
  | 'card_team'
  | 'do_dont_section'
  | 'card_visual_form'
  | 'card_icon';

export type IComponents = {
  [key in ComponentNames]: LoadableComponent<any>;
};

const Components: IComponents = {
  page: loadable(() => import('@components/Page')),
  grid: loadable(() => import('@components/grid')),
  parent: loadable(() => import('@components/parent')),
  section_default: loadable(() => import('@components/section_default')),
  section_full_width: loadable(() => import('@components/section_full_width')),
  hero: loadable(() => import('@components/hero')),
  centre_text: loadable(() => import('@components/centre_text')),
  layout_split: loadable(() => import('@components/layout_split')),
  nav_item: loadable(() => import('@common/Navbar/components/item')),
  button: loadable(() => import('@components/button')),
  value_features: loadable(() => import('@components/value_features')),
  audience_features: loadable(() => import('@components/audience_features')),
  footer: loadable(() => import('@components/footer')),
  homepage_navi: loadable(() => import('@components/navbar')),
  footer_nav_item: loadable(() => import('@components/footer_nav_item')),
  button_row: loadable(() => import('@components/button_row')),
  // Docs
  docs_page: loadable(() => import('@components/docs_page')),
  docs_code_page: loadable(() => import('@components/docs_page')),
  docs_navi: loadable(() => import('@components/docs_navi')),
  docs_nav_card: loadable(() => import('@components/docs_nav_card.jsx')),
  docs_header: loadable(() => import('@components/docs_header')),
  docs_subheader: loadable(() => import('@components/docs_subheader')),
  docs_grid: loadable(() => import('@components/docs_grid')),
  sub_nav_item: loadable(() => import('@components/sub_nav_item')),
  docs_page_nav: loadable(() => import('@components/docs_page_nav')),
  docs_page_nav_item: loadable(() => import('@components/docs_page_nav_item')),
  docs_page_nav_icon: loadable(() => import('@components/docs_page_nav_icon')),
  docs_page_grid: loadable(() => import('@components/docs_page_grid')),
  docs_body: loadable(() => import('@components/docs_body')),
  docs_content: loadable(() => import('@components/docs_content')),
  docs_card: loadable(() => import('@components/docs_card')),
  docs_title: loadable(() => import('@components/docs_title')),
  docs_divider: loadable(() => import('@components/docs_divider')),
  docs_image: loadable(() => import('@components/docs_image')),
  values_principles: loadable(() => import('@components/values_principles')),
  principles: loadable(() => import('@components/principles')),
  tooltip: loadable(() => import('@components/tooltip')),
  tag: loadable(() => import('@components/tag')),
  docs_ui_page: loadable(() => import('@components/docs_page')),
  docs_overview_page: loadable(() => import('@components/docs_overview_page')),
  doc_nav: loadable(() => import('@components/doc_nav')),
  doc_nav_zero: loadable(() => import('@components/doc_nav_zero')),
  doc_nav_first: loadable(() => import('@components/doc_nav_first')),
  doc_nav_second: loadable(() => import('@components/doc_nav_second')),
  doc_nav_third: loadable(() => import('@components/doc_nav_third')),
  doc_nav_fourth: loadable(() => import('@components/doc_nav_fourth')),
  code_blok: loadable(() => import('@components/code_blok')),
  checkbox: loadable(() => import('@components/checkbox')),
  docs_tabs: loadable(() => import('@components/docs_tabs')),
  docs_tab: loadable(() => import('@components/docs_tab')),
  docs_tab_panel: loadable(() => import('@components/docs_tab_panel')),
  // Table
  table: loadable(() => import('@components/table')),
  table_row: loadable(() => import('@components/table_row')),
  cell_default: loadable(() => import('@components/cell_default')),
  cell_color: loadable(() => import('@components/cell_color')),
  cell_icon: loadable(() => import('@components/cell_icon')),
  cell_image: loadable(() => import('@components/cell_image')),
  docs_fw_image: loadable(() => import('@components/docs_fw_image')),
  important_message: loadable(() => import('@components/important_message')),
  cell_multi_icon: loadable(() => import('@components/cell_multi_icon')),
  sub_section: loadable(() => import('@components/sub_section')),
  // Card
  content_card: loadable(() => import('@components/content_card')),
  card_color: loadable(() => import('@components/card_color')),
  card_color_large: loadable(() => import('@components/card_color_large')),
  card_color_accessibility: loadable(() => import('@components/card_color_accessibility')),
  card_note: loadable(() => import('@components/card_note')),
  card_component: loadable(() => import('@components/card_note')),
  card_image: loadable(() => import('@components/card_image')),
  card_link: loadable(() => import('@components/card_link')),
  card_do_dont: loadable(() => import('@components/card_do_dont')),
  card_tag: loadable(() => import('@components/card_tag')),
  card_team: loadable(() => import('@components/card_team')),
  do_dont_section: loadable(() => import('@components/do_dont_section')),
  card_visual_form: loadable(() => import('@components/card_visual_form')),
  card_icon: loadable(() => import('@components/card_icon')),
};

export default Components;
