import { tv } from 'tailwind-variants';

export const buttonStyle = tv({
  base: [
    'flex justify-middle items-center',
    'w-fit py-3 px-6',
    'rounded-full',
    'font-bold leading-6',
  ],
  variants: {
    opacity: {
      default: 'opacity-100',
      disabled: 'opacity-30',
    },
  },
});
export const primaryButton = tv({
  extend: buttonStyle,
  base: [
    'text-button-filled-enabled hover:text-button-filled-hover',
    'bg-button-filled-enabled hover:bg-button-filled-hover',
    'focus-visible:ring-2 focus-visible:ring-accent',
  ],
});

export const secondaryButton = tv({
  extend: buttonStyle,
  base: [
    'border',
    'text-button-outlined-enabled hover:text-button-outlined-hover',
    'bg-button-outlined-enabled hover:bg-button-outlined-hover',
    'focus-visible:ring-2 focus-visible:ring-accent',
  ],
});

export const tertiaryButton = tv({
  extend: buttonStyle,
  base: [
    'text-button-ghost-enabled hover:text-button-ghost-hover rounded-md',
    'bg-button-ghost-enabled',
    'hover:underline',
    'focus-visible:ring-2 focus-visible:ring-accent',
  ],
  variants: {
    darkMode: {
      true: 'text-button-dark-ghost-enabled',
    },
    cardLink: {
      true: 'p-0',
    },
    active: {
      true: 'text-button-ghost-hover underline',
    },
  },
});

export const tonalButton = tv({
  extend: buttonStyle,
  base: ['text-button-tonal-enabled', 'bg-button-tonal-enabled hover:bg-button-tonal-hover'],
  variants: {
    size: { sm: 'text-sm p-1 px-2' },
  },
});
