import { tv } from 'tailwind-variants';

export const cardIconStyle = tv({
  slots: {
    base: ['focus-visible:ring-2 focus-visible:ring-accent'],
    header: ['flex items-center pb-4'],
    icon: [
      'flex justify-center items-center p-2 mr-4 rounded-lg',
      'bg-neutral-lightShade text-neutral',
    ],
    title: ['w-full', 'font-bold [&>p]:text-neutral [&>p]:m-0 [&>h5]:text-neutral'],
    body: ['pb-4 h-full', '[&>p]:text-sm [&>p]:text-neutral'],
    separator: 'h-px w-full border-b border-neutral-lowContrast',
    buttonRow: 'pt-4',
    button: ['bg-transparent', 'text-sm text-neutral font-bold'],
    outboundIcon: 'text-neutral ml-2',
  },
  variants: {
    active: {
      true: {
        icon: 'text-accent',
        title: '[&>p]:text-accent [&>h5]:text-accent',
        button: 'text-accent underline',
        outboundIcon: 'text-accent',
      },
    },
    type: {
      small: { header: 'p-0', icon: ['p-0 mr-2', 'bg-transparent'], title: '[&>p]:leading-6' },
    },
  },
});

export const cardNavStyle = tv({
  slots: {
    base: ['focus-visible:ring-2 focus-visible:ring-accent'],
    icon: [
      'flex justify-center items-center p-2 rounded-lg',
      'text-neutral',
      'transition-all duration-short ease-in-out',
    ],
    title: ['w-full pt-6 pb-2 px-3', 'font-bold text-neutral text-center'],
    separator: 'h-px border-b border-neutral-lowContrast',
    tag: [
      'absolute right-0 top-0',
      'm-2 p-2 rounded-lg',
      'text-xs leading-2 text-neutral-highContrast',
      'bg-tag-enabled',
    ],
    image: 'relative h-36 pb-4 w-full',
  },
  variants: {
    disable: {
      true: 'opacity-30',
    },
    active: {
      true: {
        icon: 'text-accent',
        title: 'text-accent underline',
      },
    },
    type: {
      small: { icon: ['w-8 h-8', 'p-0 mr-2'], title: ['w-auto p-0 pl-2'], image: ['p-0 h-full'] },
    },
  },
});

export const cardLinkStyle = tv({
  slots: {
    base: [
      'flex flex-col items-center justify-center',
      'focus-visible:ring-2 focus-visible:ring-accent',
    ],
    header: ['flex items-center pb-4', 'transition-all duration-short ease-in-out'],
    title: ['w-full', 'font-bold [&>p]:text-neutral [&>p]:m-0 [&>h5]:text-neutral'],
    body: ['pb-4 h-full', '[&>p]:text-sm'],
    separator: 'h-px w-full border-b border-neutral-lowContrast',
    buttonRow: 'pt-4',
    button: ['pt-4 mt-4 relative'],
    outboundIcon: 'text-sm text-accent ml-2',
  },
  variants: {
    active: {
      true: {
        title: '[&>p]:text-accent [&>h5]:text-accent',
        button: 'text-accent underline',
      },
    },
  },
});

export const cardColorAccessibilityStyle = tv({
  slots: {
    base: [
      'h-[9.25rem] -mt-4 mb-4 -mx-4 p-2',
      'flex justify-center items-end flex-wrap',
      'text-center',
      'focus-visible:ring-2 focus-visible:ring-accent',
    ],
    row: 'flex flex-wrap',
    caption: ['flex flex-col justify-end mr-1', 'last:mr-0'],
    captionLg: 'text-lg',
    tag: [
      'inline-flex relative items-center p-2 rounded-lg',
      'text-xs',
      'bg-tag-enabled',
      '[&>svg]:mr-2 [&>svg]:leading-4',
    ],
  },
});
export const cardColorLarge = tv({
  slots: {
    base: [
      'flex flex-col h-full relative',
      'text-center',
      'min-h-[11.5rem]',
      'focus-visible:ring-2 focus-visible:ring-accent',
    ],
    title: 'flex text-sm items-center pb-4',
    square: 'flex w-4 h-4 rounded-sm border border-neutral-table mr-2 overflow-hidden',
    body: ['h-full my-0 -mx-4', 'border-t border-t-neutral-lowContrast'],
    list: 'w-full flex flex-col pt-4 gap-x-2',
    item: 'flex w-full items-center text-left',
    text: 'text-xs leading-8 w-10 m-0',
    value: 'text-sm leading-9 flex-1 py-0 px-1',
    button: [
      'w-6 h-6 text-sm',
      'flex justify-center items-center transition-all duration-short',
      'hover:text-accent',
      'focus-visible:rounded-md focus-visible:ring-2 focus-visible:ring-accent',
    ],
  },
  variants: {
    accessibility: {
      true: {
        title: 'pb-0 bg-red',
      },
    },
  },
});

export const cardImageStyle = tv({
  slots: {
    base: [''],
    header: [
      'flex items-center relative',
      'pb-4 mb-4 font-bold',
      'border-b border-b-neutral-lowContrast',
    ],
    image: 'cursor-pointer [&>img]:h-full',
    body: [
      'relative',
      'mt-4 pt-4 h-full',
      'border-t border-t-neutral-lowContrast',
      '[&>p]:text-sm',
    ],
  },
  variants: {
    hidden: {
      title: 'hidden',
    },
    fw: {
      true: { image: '-mt-4 -mx-4 mb-0', header: 'border-b-0', body: 'mt-0 border-t-0' },
    },
    hideImage: { true: { body: 'mt-0 pt-0 border-t-0' } },
  },
});

export const cardTeamStyle = tv({
  slots: {
    base: ['h-96 flex flex-col'],
    image: 'w-full h-full transition-all duration-short rounded-lg',
    tag: 'absolute -bottom-4 -right-2 w-max',
    container: ['flex flex-col items-start h-1/5 transition-all duration-short'],
    imageContainer: 'relative h-3/4 transition-all duration-short',
    titleContainer: [
      'flex justify-center flex-col',
      'translate-y-6 scale-100 origin-left',
      '[&>h4]:mb-2 [&>h4]:font-bold',
      'transition-all duration-short',
    ],
    title: 'text-4',
    name: 'text-6 font-bold pt-2',
    infoContainer: [
      'opacity-0 translate-y-full invisible',
      'pt-2 border-t border-t-neutral-lowContrast',
      'transition-all duration-short',
    ],
  },
  variants: {
    active: {
      true: {
        container: 'h-1/2',
        imageContainer: 'h-1/2',
        titleContainer: 'scale-75 translate-y-0',
        infoContainer: 'translate-y-0 opacity-100 visible',
      },
    },
  },
});

export const cardVisualFormStyle = tv({
  slots: {
    base: [
      'flex flex-col relative',
      'h-full p-4 bg-neutral',
      'overflow-hidden',
      'border border-neutral-lowContrast rounded-lg',
    ],
    content: [
      'min-h-36 flex-[.9_1]',
      'border-b border-b-neutral-lowContrast',
      'my-0 -mx-4 pt-4 pb-0 px-4',
    ],
    styleRow: 'flex justify-between items-center',
    infoContainer: 'font-xs pt-4',
    infoLink: [
      'flex justify-between items-center',
      'w-full border-0 bg-transparent py-1 px-0',
      'text-neutral hover:text-accent font-xs',
      'transition-all duration-short cursor-pointer',
      'focus-visible:rounded-md focus-visible:ring-2 focus-visible:ring-accent',
    ],
    link: 'mr-1 whitespace-pre-wrap font-xs',
    icon: 'ml-1',
    infoTitle: 'font-bold pb-2 absolute top-4 left-4',
    subTitle: 'pb-2 [&>p]:m-0 [&>p]:p-0 [&>p]:text-sm [&>p]:top-0',
  },
  variants: {
    opacity: { true: { content: '-mt-4 -mx-4 mb-0 min-h-40' } },
    br: { true: { infoContainer: 'my-0 mx-10', infoTitle: 'left-[40%]' } },
  },
});

export const cardValuesStyle = tv({
  slots: {
    container: [
      'my-4 mx-0',
      'w-full h-full relative flex flex-col justify-between',
      'transition-all duration-short',
    ],
    content: [
      'absolute h-auto bg-neutral w-full text-center',
      'border border-neutral-lowContrast rounded-xl',
      'transition-all duration-short',
    ],
    iconContainer: [
      'absolute w-full h-2/5',
      'z-10 top-4 py-4',
      'rounded-xl w-20',
      '-translate-x-1/2 translate-y-0 left-1/2',
      'transition-all duration-mid',
    ],
    icon: 'w-full h-full max-h-[15rem]',
    titleContainer: ['absolute w-full bottom-14', 'transition-all duration-mid', 'opacity-100'],
    hoverContainer: [
      'relative w-full z-10',
      'py-8 px-6',
      'opacity-0',
      'transition-all duration-mid',
    ],
    label: ['py-4 px-0 w-full cursor-pointer'],
    subtitle: [
      'text-xs font-bold text-neutral-lowContrast',
      'py-2 px-0',
      'translate-y-0',
      'transition-all duration-mid',
    ],
    previewContainer: ['relative text-2xl font-semibold top-0 min-h-[4rem] lg:min-h-[6.5rem]'],
    preview: ['block absolute w-full', 'top-1/2 -translate-y-1/2', 'transition-all duration-mid'],
    chevron: [
      'hidden lg:block relative m-auto text-neutral w-14 z-[100]',
      'translate-y-4',
      'transition-all duration-short',
    ],
    accordionContainer: ['relative', 'transition-all duration-short', 'h-0 invisible opacity-0'],
    accordion: ['block absolute w-full', 'top-1/2 -translate-y-1/2', '[&>p]:text-sm'],
    svgContainer: ['transition-opacity duration-mid', 'opacity-0'],
  },
  variants: {
    direction: {
      up: {
        content: '-bottom-3',
        chevron: 'absolute bottom-4 inset-x-0',
        hoverContainer: 'mb-8',
      },
    },
    checked: {
      true: {
        content: 'z-[100] min-h-full',
        titleContainer: 'opacity-0',
        hoverContainer: 'opacity-100',
        chevron: 'translate-y-0',
        accordionContainer:
          'h-32 lg:h-80 visible opacity-100 border-t border-t-neutral-lowContrast',
      },
    },
    hovered: {
      true: {
        iconContainer:
          'w-14 h-14 p-3 z-995 -translate-x-1/2 -translate-y-4 left-1/2 -top-4 bg-neutral-black rounded-xl',
        titleContainer: 'opacity-0',
        hoverContainer: 'opacity-100',
        chevron: 'text-accent',
      },
    },
  },
});
