import { getCookie, parseJwt } from './share';

const Token = 'CF_Authorization';

export function isAuthenticated() {
  const jwt = getCookie(Token);

  return !!jwt;
}

interface CFAuthorizationProps {
  aud: string[];
  country: string;
  email: string;
  exp: number;
  iat: number;
  identity_nonce: string;
  iss: string;
  nbf: number;
  sub: string;
  type: string;
}

export function getUserInitials() {
  const user = parseJwt<CFAuthorizationProps>(getCookie(Token) as string);

  if (!user) return;

  const { email } = user;

  const [fullName] = email.split('@');

  return getInitials(fullName);
}

const getInitials = (fullName: string) => {
  const allNames = fullName.trim().split('.');

  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) acc = `${acc}${curr.charAt(0).toUpperCase()}`;

    return acc;
  }, '');

  return initials;
};
