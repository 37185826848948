import type { SafeJson } from '@types';

export function isString(content: unknown): content is string {
  return typeof content === 'string';
}

export const parseJson = (payload: any): unknown => isString(payload) && JSON.parse(payload);

export const safeJsonParse = <T>(guard: (o: any) => o is T) => (
  text: string,
): SafeJson.ParseResult<T> => {
  const parsed = JSON.parse(text);
  return guard(parsed) ? { parsed, hasError: false } : { hasError: true };
};

export function isObject<T extends object>(
  obj: { [K in keyof T]: any },
  props?: (keyof T)[],
): obj is T {
  if (!obj) return false;

  if (Object.keys(obj).some((p) => props?.includes(p as any))) return true;

  if (obj && Object.keys(obj).length <= 0) return true;

  return false;
}

export const isBrowser = () => typeof window !== 'undefined';

export function getCookie(name: string) {
  if (!isBrowser()) return;

  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop()!.split(';').shift();
  }
}

export function parseJwt<P>(token: string): P | undefined {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload) as P;
}

/**
 * Polyfill to copy text since it breaks in http, but not in https
 * @param {*} textToCopy
 */
export function copyToClipboard(textToCopy) {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  }

  // text area method
  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;
  // make the textarea out of viewport
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  return new Promise((res, rej) => {
    // here the magic happens
    document.execCommand('copy') ? res() : rej();

    textArea.remove();
  });
}
