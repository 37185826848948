import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import Link from 'gatsby-link';

import { navItem } from '@components/styles/nav_item_appearance';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAtom, faCode, faDrawSquare, faHeart } from '@fortawesome/sharp-regular-svg-icons';

function OverviewNavItem(props) {
  const [className, setClassName] = useState('');

  const { base, title, icon, item } = navItem({ type: 'vertical' });

  useEffect(() => {
    if (props.pageTheme === props.id) {
      setClassName('bg-selected');
    }
  }, [props.pageTheme, className]);

  function renderIcon() {
    if (props.id === 'overview') {
      return <FontAwesomeIcon icon={faDrawSquare} />;
    }
    if (props.id === 'brand') {
      return <FontAwesomeIcon icon={faHeart} />;
    }
    if (props.id === 'ui') {
      return <FontAwesomeIcon icon={faAtom} />;
    }
    if (props.id === 'code') {
      return <FontAwesomeIcon icon={faCode} />;
    }
  }

  return (
    <Link
      role="button"
      to={'/' + props.link}
      className={clsx(base(), item(), className)}
      id={props.id}
    >
      <div className={icon()}>{renderIcon()}</div>
      <div className={title()}>{props.text}</div>
    </Link>
  );
}

export default OverviewNavItem;
