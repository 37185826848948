import { tv } from 'tailwind-variants';

export const navItem = tv({
  slots: {
    base: ['block relative rounded-lg'],
    group: ['flex flex-col  px-0 py-0'],
    item: [
      'flex items-center justify-center',
      'relative w-full p-2 mb-2',
      'text-neutral-highContrast rounded-lg',
      'transition-all duration-short ease-in-out',
      'hover:bg-hover',
      'focus-visible:ring-2 focus-visible:ring-accent',
    ],
    title: 'text-sm w-full',
    icon: ['w-6 h-6', 'flex items-center justify-center'],
  },
  variants: {
    disabled: {
      true: { base: 'opacity-30 pointer-events-none' },
    },
    type: {
      vertical: {
        item: 'flex-col items-center text-center',
        title: 'text-xs',
        icon: 'text-neutral',
      },
      first: { base: 'ml-2', group: 'pl-2' },
      second: { group: 'pl-2' },
      third: { group: 'pl-2' },
    },
    active: {
      true: {
        item: 'bg-selected',
      },
      closed: {
        group: 'py-2 border-0',
      },
    },
  },
});
