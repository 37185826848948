import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockAlt } from '@fortawesome/sharp-regular-svg-icons';

interface Props extends React.PropsWithChildren<{}> {
  lock?: boolean;
}

export default function SubNavLock(props: Props): JSX.Element {
  const renderLock = () => {
    return (
      <span className={'w-6 h-6 flex items-center justify-center'}>
        <FontAwesomeIcon icon={faLockAlt} color="inherit" />
      </span>
    );
  };

  return (
    <div className={'flex items-center justify-center text-sm w-full'}>
      {props.children}
      {props.lock && renderLock()}
    </div>
  );
}
