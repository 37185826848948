import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faLockAlt, faBars, faTimes } from '@fortawesome/sharp-regular-svg-icons';
import { SbEditableContent } from 'storyblok-react';

import { usePageCtx } from '@layout';

import DynamicComponent from '@components/DynamicComponent';
import { getUserInitials, isAuthenticated } from '../utils/authentication';
import { useState } from 'react';

import clsx from 'clsx';

export default function Navbar({ blok }: Partial<SbEditableContent>): JSX.Element {
  const [open, setOpen] = useState(false);
  const [check, setCheck] = useState(false);

  const { theme } = usePageCtx();

  const handleDropdown = (e: any) => {
    setOpen(!open);
  };
  const openNav = (e: any) => {
    setCheck(!check);
  };
  const renderInitials = () => {
    const initials = getUserInitials();

    return (
      <div onClick={() => setOpen(!open)}>
        <div
          className={clsx(
            'flex flex-column relative items-center justify-center',
            'w-8 h-8 p-1',
            'bg-primary rounded-full',
            'cursor-pointer',
          )}
        >
          <span className={clsx('text-sm')}>{initials}</span>

          {open && (
            <a
              href="/cdn-cgi/access/logout"
              className="absolute top-12 -right-2 md:-right-9 w-max"
              onClick={handleDropdown}
            >
              <div className={clsx('flex bg-neutral-black py-3 px-4')}>
                <div className={clsx('flex items-center justify-start group')}>
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    className={clsx('text-neutral-white group-hover:text-accent')}
                  />
                  <p className={clsx('text-neutral-white my-0 ml-4 group-hover:text-accent')}>
                    Log out
                  </p>
                </div>
              </div>
            </a>
          )}
        </div>
      </div>
    );
  };

  const renderLogin = () => {
    return (
      <a
        href="/auth/"
        className={clsx(
          'w-10 h-10',
          'flex justify-center items-center',
          'text-button-dark-ghost-enabled hover:text-button-ghost-hover',
        )}
      >
        <FontAwesomeIcon icon={faLockAlt} />
      </a>
    );
  };

  return (
    <>
      <input type="checkbox" id={theme === undefined ? 'menu' : 'docs-menu'} onClick={openNav} />
      <div
        className={clsx(
          'fixed flex z-999 cursor-pointer text-neutral-white',
          'right-0 top-0 w-12 h-16 items-center justify-center m-0',
          'md:hidden',
        )}
      >
        {isAuthenticated() ? renderInitials() : renderLogin()}
      </div>
      <label
        className={clsx(
          'fixed flex z-999 cursor-pointer text-neutral-white',
          'right-12 top-0 w-12 h-16 items-center justify-center m-0',
          'md:hidden',
        )}
        htmlFor={theme === undefined ? 'menu' : 'docs-menu'}
      >
        {check ? <FontAwesomeIcon icon={faTimes} /> : <FontAwesomeIcon icon={faBars} />}
      </label>
      <nav
        className={clsx(
          'flex flex-nowrap	flex-row',
          'justify-between items-center',
          'w-full h-16',
          'px-5',
          'top-0 z-998',
          'bg-neutral-black',
          'fixed',
        )}
        id="homepage-nav"
      >
        <a href="/">
          <div className="w-10">
            <img
              src="https://truelayer-public-assets.s3-eu-west-1.amazonaws.com/logo/mark/tl-white.svg"
              alt="logo"
            />
          </div>
        </a>
        <div
          className={clsx(
            'block absolute w-full h-screen',
            'm-0 mt-14',
            'bg-neutral-black',
            'top-0',
            'transition-all duration-short',
            check ? 'opacity-100 right-0' : 'opacity-0 -right-full',
            'md:relative md:w-auto md:h-16 md:mt-0 md:opacity-100 md:items-center md:right-0',
          )}
        >
          <ul className={clsx('flex flex-col items-center', 'md:flex-row')}>
            <li
              className={clsx(
                'flex flex-col items-center w-full h-16',
                '[&>a]:h-full [&>a]:w-full [&>a]:px-4 [&>a]:whitespace-nowrap [&>a]:justify-center',
                'md:flex-row',
              )}
            >
              {blok.nav_items &&
                blok.nav_items.map((_blok: SbEditableContent) => (
                  <DynamicComponent blok={_blok} key={_blok._uid} />
                ))}
            </li>
            <div className={clsx('hidden md:block')}>
              {isAuthenticated() ? renderInitials() : renderLogin()}
            </div>
          </ul>
        </div>
      </nav>
    </>
  );
}
