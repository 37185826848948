import React from 'react';
import { SbEditableContent } from 'storyblok-react';

import { default as Components } from '@common';
import type { ComponentNames } from '@common';
import NotFound from '@common/NotFound';

export default function DynamicComponent({
  blok,
  ...rest
}: Partial<SbEditableContent>): JSX.Element {
  if (!blok) return <h1>Blok {blok} is empty</h1>;

  if (!isComponent(blok.component)) return <NotFound component={blok.component} />;
  const { component }: { component: ComponentNames } = blok;
  const Block = Components[component];
  return <Block blok={blok} key={blok._uid} {...rest} />;
}

function isComponent(name: string): boolean {
  return typeof Components[name as ComponentNames] !== 'undefined';
}
