import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { globalHistory } from '@reach/router';

export default function SEO({ title }: { title?: string }): JSX.Element {
  const [pageTitle, setPageTitle] = useState('');
  useEffect(() => {
    let title = globalHistory.location.pathname.replace(/[\[\]?.,\/#!$%\^&\*;:{}=\\|_~()]/g, ' ');
    let title2 = title.split(' ').filter((element) => element);
    let title3;
    title2[title2.length - 1] === undefined
      ? (title3 = '')
      : (title3 = title2[title2.length - 1].split('-').join(' '));
    title3 === '' ? setPageTitle('') : setPageTitle(' | ' + title3);
  }, []);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title data-react-helmet="true">{"TrueLayer's design system" + pageTitle}</title>
      <meta property="og:title" content="TrueLayer's design system" />
      <meta
        property="og:description"
        name="description"
        content="The single source of truth for building products that are scalable and on brand. Built by the Prisma team, it comprises a wide range of style guides, component libraries and implementation guidelines."
      />
      <meta
        property="og:image"
        content="https://a.storyblok.com/f/125631/3432x1978/2583799d4a/homepage-2.png"
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="667" />
      <meta property="og:url" content="https://prisma.truelayer.com/" />
    </Helmet>
  );
}
